import './ReleaseNotes.scss';

export const ReleaseNotes = () => (
    <div className="ReleaseNotes">
        <h2>Release Notes</h2>
        <div className="release-title">Feb 2024 Release</div>
        <table border={1} cellPadding="3" cellSpacing="1" width="100%">
            <thead>
                <tr>
                    <th className="colHeaderLink headerrow-issuekey">Key</th>
                    <th className="colHeaderLink headerrow-summary">Summary</th>
                    <th className="colHeaderLink headerrow-updated">Updated</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>PROT-12968</td>
                    <td>Lightweight automated actions page | Phase 1</td>
                    <td>13/Feb/24 10:49 AM</td>
                </tr>
            </tbody>
        </table>
        <div className="release-title">Jan 2024 Release</div>
        <table border={1} cellPadding="3" cellSpacing="1" width="100%">
            <thead>
                <tr>
                    <th className="colHeaderLink headerrow-issuekey">Key</th>
                    <th className="colHeaderLink headerrow-summary">Summary</th>
                    <th className="colHeaderLink headerrow-updated">Updated</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>PROT-15419</td>
                    <td>Improve Endpoint Filter Granularity</td>
                    <td>24/Jan/24 3:32 PM</td>
                </tr>
                <tr>
                    <td>PROT-15558</td>
                    <td>Allow Akamai Users to securely access API Security advanced settings</td>
                    <td>17/Jan/24 9:55 AM</td>
                </tr>
                <tr>
                    <td>PROT-2199</td>
                    <td>Configurable Service Mapping</td>
                    <td>08/Jan/24 4:02 PM</td>
                </tr>
                <tr>
                    <td>PROT-2246</td>
                    <td>Dashboards page | Discovery Dashboard</td>
                    <td>08/Jan/24 4:02 PM</td>
                </tr>
                <tr>
                    <td>PROT-9894</td>
                    <td>De-tokenization in Neosec UI for On-CDN tenants</td>
                    <td>08/Jan/24 4:02 PM</td>
                </tr>
                <tr>
                    <td>PROT-15257</td>
                    <td>Entity Management | Soft delete entities</td>
                    <td>08/Jan/24 3:59 PM</td>
                </tr>
            </tbody>
        </table>
        <div className="release-title">Dec 2023 Release</div>
        <table border={1} cellPadding="3" cellSpacing="1" width="100%">
            <thead>
                <tr>
                    <th className="colHeaderLink headerrow-issuekey">Key</th>
                    <th className="colHeaderLink headerrow-summary">Summary</th>
                    <th className="colHeaderLink headerrow-updated">Updated</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>PROT-8689</td>
                    <td>Better display of payloads in the UI (JSON and XML)</td>
                    <td>06/Dec/23 12:20 PM</td>
                </tr>
            </tbody>
        </table>
        <div className="release-title">Sep 2023 Release</div>
        <table border={1} cellPadding="3" cellSpacing="1" width="100%">
            <thead>
                <tr>
                    <th className="colHeaderLink headerrow-issuekey">Key</th>
                    <th className="colHeaderLink headerrow-summary">Summary</th>
                    <th className="colHeaderLink headerrow-updated">Updated</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>PROT-12689</td>
                    <td>Akamai Integration | SSO MVP Implementation</td>
                    <td>10/Sep/23 3:14 PM</td>
                </tr>
            </tbody>
        </table>

        <div className="release-title">July 2023 Release</div>
        <table border={1} cellPadding="3" cellSpacing="1" width="100%">
            <thead>
                <tr>
                    <th className="colHeaderLink headerrow-issuekey">Key</th>
                    <th className="colHeaderLink headerrow-summary">Summary</th>
                    <th className="colHeaderLink headerrow-updated">Updated</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>PROT-13138</td>
                    <td>Rebranding UI with Akamai top bar and logo</td>
                    <td>25/Jul/23 11:53 AM</td>
                </tr>
            </tbody>
        </table>
        <div className="release-title">June 2023 Release</div>
        <table border={1} cellPadding="3" cellSpacing="1" width="100%">
            <thead>
                <tr>
                    <th className="colHeaderLink headerrow-issuekey">Key</th>
                    <th className="colHeaderLink headerrow-summary">Summary</th>
                    <th className="colHeaderLink headerrow-updated">Updated</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>PROT-8548</td>
                    <td>Endpoint type classification</td>
                    <td>21/Jun/23 10:21 AM</td>
                </tr>
            </tbody>
        </table>
        <div className="release-title">May 2023 Release</div>
        <table border={1} cellPadding="3" cellSpacing="1" width="100%">
            <thead>
                <tr>
                    <th className="colHeaderLink headerrow-issuekey">Key</th>
                    <th className="colHeaderLink headerrow-summary">Summary</th>
                    <th className="colHeaderLink headerrow-updated">Updated</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>PROT-8268</td>
                    <td>Display tenant name and user in the UI top bar</td>
                    <td>29/May/23 12:02 PM</td>
                </tr>
            </tbody>
        </table>
        <div className="release-title">Apr 2023 Release</div>
        <table border={1} cellPadding="3" cellSpacing="1" width="100%">
            <thead>
                <tr>
                    <th className="colHeaderLink headerrow-issuekey">Key</th>
                    <th className="colHeaderLink headerrow-summary">Summary</th>
                    <th className="colHeaderLink headerrow-updated">Updated</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>PROT-9936</td>
                    <td>Alerts | Comments on Alerts</td>
                    <td>17/Apr/23 11:57 AM</td>
                </tr>
            </tbody>
        </table>
        <div className="release-title">Feb 2023 Release</div>
        <table border={1} cellPadding="3" cellSpacing="1" width="100%">
            <thead>
                <tr>
                    <th className="colHeaderLink headerrow-issuekey">Key</th>
                    <th className="colHeaderLink headerrow-summary">Summary</th>
                    <th className="colHeaderLink headerrow-updated">Updated</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>PROT-12968</td>
                    <td>Lightweight automated actions page | Phase 1</td>
                    <td>13/Feb/24 10:49 AM</td>
                </tr>
            </tbody>
        </table>
        <div className="release-title">Jan 2023 Release</div>
        <table border={1} cellPadding="3" cellSpacing="1" width="100%">
            <thead>
                <tr>
                    <th className="colHeaderLink headerrow-issuekey">Key</th>
                    <th className="colHeaderLink headerrow-summary">Summary</th>
                    <th className="colHeaderLink headerrow-updated">Updated</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>PROT-1356</td>
                    <td>Alerts Table | Add 'Export to CSV' functionality</td>
                    <td>31/Jan/23 9:12 AM</td>
                </tr>
                <tr>
                    <td>PROT-8802</td>
                    <td>Collector | Neosec Node images to simplify installation</td>
                    <td>22/Jan/23 2:06 PM</td>
                </tr>
                <tr>
                    <td>PROT-6355</td>
                    <td>UI | New Look and Feel</td>
                    <td>22/Jan/23 10:51 AM</td>
                </tr>
                <tr>
                    <td>PROT-8106</td>
                    <td>Status Indication in Entity Timeline</td>
                    <td>16/Jan/23 3:58 PM</td>
                </tr>
                <tr>
                    <td>PROT-7502</td>
                    <td>Upgrade several key libraries FE uses</td>
                    <td>16/Jan/23 3:58 PM</td>
                </tr>
            </tbody>
        </table>
    </div>
);
